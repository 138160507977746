import React from 'react';
import PropTypes from 'prop-types';
import {createUserActivate, createUserSendActivationEmail} from '../../../../backend/apiCalls';
import {Button, Form} from 'antd';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../../constants/form';
import {Trans, t} from '@lingui/macro';
import InputText from '../../../general/InputText';
import FormModal from '../../../fetch/FormModal';
import generalNoParameterFormPC from '../../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../../fetch/generalFailedPC';
import { UserInfo } from '../../../../constants/propTypesDefinitions';
import withSessionHOC from '../../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../../sessionProvider/SessionProvider';
const FormItem = Form.Item;
/**
 * @fero
 */

class ActivationForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            userInfo: UserInfo.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, [SESSION_ATTRIBUTES.RELOAD_RIGHTS]: reloadRights} = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({loading: true});
                createUserActivate()(values).then((response) => {
                    this.setState({loading: false});
                    reloadRights();
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose, values} = this.props;
        const {userInfo} = values;
        const userInfoChecked = userInfo != null ? userInfo : {};
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="">
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Aktivačný kód</Trans>}
                >
                    {getFieldDecorator('token', {rules: [mandatoryRule]})(
                        <InputText 
                            ref={node => this.first = node}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Aktivačný e-mail neprišiel</Trans>}
                >
                    <SendActivationEmail mail={userInfoChecked.e_mail}/>
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                    <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                    <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                        <Trans>Aktivovať</Trans>
                    </Button>
                </FormItem>
            </Form>
        );
    }

}

export default Form.create()(withSessionHOC([SESSION_ATTRIBUTES.RELOAD_RIGHTS])(ActivationForm));


class SendActivationEmail extends React.PureComponent {
    static propTypes = {
        mail: PropTypes.string,
    };

    render() {
        const {mail} = this.props;
        const disabled = mail == null || mail == '';
        return <FormModal
            disabled={disabled}
            openNode={
                <Button icon="sync" disabled={disabled}>
                    <span className="ml-2"><Trans>Znovu odoslať aktivačný e-mail</Trans></span>
                </Button>
            }
            values={{mail}}
            title={<Trans>Aktivačný e-mail</Trans>}
            Form={generalNoParameterFormPC(
                <div>
                    <Trans>Naozaj chcete znovu odoslať aktivačný e-mail na adresu</Trans>
                    {' '}
                    <span className="text-nowrap">{mail}</span>
                    {'?'}
                </div>,
                createUserSendActivationEmail()
            )}
            Response={SendActivationEmailResponse}
            Failed={generalFailedPC(t`Nepodarilo sa odoslať aktivačný e-mail.`)}
        />;
    }
}

class SendActivationEmailResponse extends React.PureComponent {
    static propTypes = {
        values: PropTypes.shape({
            mail: PropTypes.string.isRequired,
        }).isRequired,
    };

    render() {
        const {values} = this.props;
        return <div>
            <h4>{t`Aktivačný e-mail bol odoslaný na adresu`}</h4>
            {values.mail}
        </div>;
    }
}

