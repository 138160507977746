import React from 'react';
import PropTypes from 'prop-types';
import AddressSelect from '../../../project/AddressSelect';
import {UserInfo, UserAddress} from '../../../../constants/propTypesDefinitions';
import {merge} from '../../../../lib/object';
import {createFetchUserEdit} from '../../../../backend/apiCalls';
import {Button, Form, Input} from 'antd';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../../constants/form';
import {Trans, t} from '@lingui/macro';
import LanguageSelect from '../../../project/LanguageSelect';
const FormItem = Form.Item;

/**
 * @fero
 */

class EditUserInfoForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            userInfo: UserInfo.isRequired,
            userAddresses: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const userInfo = values.userInfo;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchUserEdit()(merge(formValues, {id: userInfo.id})).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose, values} = this.props;
        const {userAddresses, userInfo} = values;
        const {getFieldDecorator} = this.props.form;
        const isAssigned = (userInfo != null && userInfo.is_assigned > 0);
        return <Form
            onSubmit={this.handleSubmit}
        >
            <FormItem
                {...formItemLayout}
                label={<Trans>Titul</Trans>}
            >
                {getFieldDecorator('title', merge({initialValue: userInfo.title}))(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Meno</Trans>}
            >
                {getFieldDecorator('name', {rules:[mandatoryRule], initialValue: userInfo.name})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Priezvisko</Trans>}
            >
                {getFieldDecorator('surname', {rules:[mandatoryRule], initialValue: userInfo.surname})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Email</Trans>}
            >
                {getFieldDecorator('e_mail', {rules:[mandatoryRule], initialValue: userInfo.e_mail})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Telefón</Trans>}
            >
                {getFieldDecorator('phone', {initialValue: userInfo.phone})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Organizácia</Trans>}
            >
                {getFieldDecorator('organization', {initialValue: userInfo.organization})(
                    <Input disabled={isAssigned}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>IČO</Trans>}
            >
                {getFieldDecorator('ico', {initialValue: userInfo.ico})(
                    <Input disabled={isAssigned}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>DIČ</Trans>}
            >
                {getFieldDecorator('dic', {initialValue: userInfo.dic})(
                    <Input disabled={isAssigned}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>IČ DPH</Trans>}
            >
                {getFieldDecorator('ic_dph', {initialValue: userInfo.ic_dph})(
                    <Input disabled={isAssigned}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Fakturačná adresa</Trans>}
            >
                {getFieldDecorator('id_invoice_address', merge({initialValue: userInfo.invoice_address != null ? userInfo.invoice_address.id : null}))(
                    <AddressSelect addresses={userAddresses} disabled={isAssigned}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Dodacia adresa</Trans>}
            >
                {getFieldDecorator('id_delivery_address', merge({initialValue: userInfo.delivery_address != null ? userInfo.delivery_address.id : null}))(
                    <AddressSelect addresses={userAddresses}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Jazyk</Trans>}
            >
                {getFieldDecorator('id_language', {initialValue: userInfo.id_language})(
                    <LanguageSelect/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    <Trans>Upraviť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

export default Form.create()(EditUserInfoForm);