import React from 'react';
import FormModal from '../../fetch/FormModal';
import ChangePasswordForm from './form/ChangePasswordForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ChangePassword extends React.PureComponent {
    render() {
        return <FormModal
            openNode={
                <Button icon="lock">
                    <span className="pl-1"><Trans>Zmena hesla</Trans></span>
                </Button>
            }
            values={{}}
            title={<Trans>Zmena hesla</Trans>}
            Form={ChangePasswordForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa zmeniť heslo.`)}
        />;
    }

}

export default ChangePassword;