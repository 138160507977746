import React from 'react';
import {UserInfo} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import InfoAttributeGroup from '../general/InfoAttributeGroup';
import Address from '../general/Address';
import ApiKey from '../project/ApiKey';

/**
 * @fero
 */

class AccountInfo extends React.PureComponent {
    static propTypes = {
        userInfo: UserInfo.isRequired,
    };

    render() {
        const { userInfo } = this.props;
        return <div className="d-flex justify-content-center px-3 pt-2 full-size-width">
            <InfoAttributeGroup
                attributes={[
                    { 
                        title: <Trans>Titul:</Trans>,  
                        value: userInfo.title
                    },
                    {
                        title: <Trans>Meno:</Trans>, 
                        value: userInfo.name
                    },
                    {
                        title: <Trans>Priezvisko:</Trans>, 
                        value: userInfo.surname
                    },
                    {
                        title: <Trans>Email:</Trans>, 
                        value: userInfo.e_mail
                    },
                    {
                        title: <Trans>Telefón:</Trans>,
                        value: userInfo.phone
                    },
                    {
                        title: <Trans>Jazyk:</Trans>, 
                        value: userInfo.language
                    },
                    {
                        title: <Trans>Organizácia:</Trans>, 
                        value: userInfo.organization
                    },
                    {
                        title: <Trans>IČO:</Trans>, 
                        value: userInfo.ico
                    },
                    {
                        title: <Trans>DIČ:</Trans>, 
                        value: userInfo.dic
                    },
                    {
                        title: <Trans>IČ DPH:</Trans>, 
                        value: userInfo.ic_dph
                    },
                    {
                        title: <Trans>Fakturačná adresa:</Trans>,
                        value: <Address address={userInfo.invoice_address}/>
                    },
                    {
                        title: <Trans>Dodacia adresa:</Trans>,
                        value: <Address address={userInfo.delivery_address}/>
                    },
                    {
                        title: <Trans>API kľúč:</Trans>,
                        value: <ApiKey apiKey={userInfo.api_key}/>
                    },
                ]}
            />
        </div>;
    }

}

export default AccountInfo;