import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import AccountInfo from './AccountInfo';
import AccountAddresses from './AccountAddresses';
import EditUserInfo from './info/EditUserInfo';
import ChangePassword from './info/ChangePassword';
import withDataHOC from '../dataProvider/withDataHOC';
import {UserInfo, UserAddress} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import { RIGHTS } from '../../constants/Rights';
import ActivateUser from './info/ActivateUser';
import PageActionsLayout from '../general/PageActionsLayout';
import {Trans, t} from '@lingui/macro';
import AttachmentsForDealerAndUser from '../project/AttachmentsForDealerAndUser';
import ChangePin from './info/ChangePin';
import GenerateApiKey from './info/GenerateApiKey';

/**
 * @fero
 */

class AccountPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
        [GLOBAL_DATA.ACCOUNT_ADDRESSES]: PropTypes.arrayOf(UserAddress).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {
            [GLOBAL_DATA.ACCOUNT_INFO]: userInfo,
            [GLOBAL_DATA.ACCOUNT_ADDRESSES]: userAddresses,
            [GLOBAL_DATA.RELOAD_DATA]: reload,
        } = this.props;

        let usedAddressIds = [];
        if(userInfo.invoice_address != null)
            usedAddressIds.push(userInfo.invoice_address.id);
            
        if(userInfo.delivery_address != null)
            usedAddressIds.push(userInfo.delivery_address.id);
        
        return <React.Fragment>
            <Helmet
                title={t`Môj účet`}
            />
            <div className="px-3 full-size-height full-size-width overflow-y-auto">
                <PageActionsLayout
                    actions={[
                        {
                            rightsFrom: RIGHTS.PENDING,
                            rightsTo: RIGHTS.PENDING,
                            node: <ActivateUser
                                userInfo={userInfo}
                                onFinished={() => reload([GLOBAL_DATA.ACCOUNT_INFO])}
                            />
                        },
                        {
                            rightsFrom: RIGHTS.CUSTOMER,
                            node: <GenerateApiKey
                                userInfo={userInfo}
                                onFinished={() => reload([GLOBAL_DATA.ACCOUNT_INFO])}
                            />
                        },
                        {
                            rightsFrom: RIGHTS.MARKETING,
                            node: <ChangePin
                                userInfo={userInfo}
                                onFinished={() => reload([GLOBAL_DATA.ACCOUNT_INFO])}
                            />
                        },
                        {
                            node: <EditUserInfo
                                userInfo={userInfo}
                                userAddresses={userAddresses}
                                onFinished={() => reload([GLOBAL_DATA.ACCOUNT_INFO])}
                            />
                        },
                        {
                            node: <ChangePassword/>
                        }
                    ]}
                />
                <h1 className="text-center"><Trans>Osobné údaje</Trans></h1>
                <AccountInfo
                    userInfo={userInfo}
                />
                <AccountAddresses
                    onReload={() => {reload([GLOBAL_DATA.ACCOUNT_ADDRESSES])}}
                    userAddresses={userAddresses}
                    userId={userInfo.id}
                    usedAddressIds={usedAddressIds}
                />
                <AttachmentsForDealerAndUser
                    userId={userInfo.id}
                    customerId={userInfo.id_customer}
                />
            </div>
        </React.Fragment>;
    }
}


export default withDataHOC([GLOBAL_DATA.ACCOUNT_INFO, GLOBAL_DATA.ACCOUNT_ADDRESSES, GLOBAL_DATA.RELOAD_DATA])(AccountPage);