import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import EditUserInfoForm from './form/EditUserInfoForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import {UserInfo, UserAddress} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class EditUserInfo extends React.PureComponent {
    static propTypes = {
        userInfo: UserInfo.isRequired,
        userAddresses: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        onFinished: PropTypes.func.isRequired,
    };

    render() {
        const {userInfo, userAddresses, onFinished} = this.props;
        return <FormModal
            openNode={
                <Button icon="edit">
                    <span className="pl-1"><Trans>Úprava účtu</Trans></span>
                </Button>
            }
            values={{userInfo, userAddresses}}
            onFinishSuccessful={onFinished}
            title={<Trans>Úprava účtu</Trans>}
            Form={EditUserInfoForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť účet.`)}
        />;
    }


}

export default EditUserInfo;