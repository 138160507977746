import React from 'react';
import PropTypes from 'prop-types';
import {createUserChangePassword} from '../../../../backend/apiCalls';
import {Button, Form, Input} from 'antd';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../../constants/form';
import {Trans, t} from '@lingui/macro';
const FormItem = Form.Item;
/**
 * @fero
 */

class ChangePasswordForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (values.new_password == values.repeat_new_password) {
                if (!err) {
                    this.setState({loading: true});
                    createUserChangePassword()(values).then((response) => {
                        this.setState({
                            loading: false,
                        });
                        if (response.status == 400 && response.result.code == 6) {
                            this.props.form.setFields({
                                old_password: {
                                    value: values.password,
                                    errors: [new Error('Nesprávne heslo.')],
                                },
                            });
                        } else {
                            onSubmitFinish(response);
                        }
                    });
                }
            } else {
                this.props.form.setFields({
                    repeat_new_password: {
                        value: values.repeat_new_password,
                        errors: [new Error('Heslo sa nezhoduje.')],
                    },
                });
            }

        });
    };

    render() {
        const {loading} = this.state;
        const {onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="">
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Súčasné heslo</Trans>}
                >
                    {getFieldDecorator('old_password', {rules: [mandatoryRule], validateTrigger: 'onSubmit'})(
                        <Input ref={node => this.first = node} type="password"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Nové heslo</Trans>}>
                    {getFieldDecorator('new_password', {rules: [mandatoryRule], validateTrigger: 'onSubmit'})(
                        <Input type="password"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Kontrola hesla</Trans>}>
                    {getFieldDecorator('repeat_new_password', {rules: [mandatoryRule], validateTrigger: 'onSubmit'})(
                        <Input type="password"/>
                    )}
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                    <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                    <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                        <Trans>Zmeniť</Trans>
                    </Button>
                </FormItem>
            </Form>
        );
    }

}

export default Form.create()(ChangePasswordForm);