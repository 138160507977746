import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../fetch/generalFailedPC';
import {UserInfo} from '../../../constants/propTypesDefinitions';
import {createFetchUserGenerateApiKey} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class GenerateApiKey extends React.PureComponent {
    static propTypes = {
        userInfo: UserInfo.isRequired,
        onFinished: PropTypes.func.isRequired,
    };

    render() {
        const {userInfo, onFinished} = this.props;
        return <FormModal
            openNode={
                    <Button icon="key">
                        <span className="ml-2"><Trans>Generovať API kľúč</Trans></span>
                    </Button>
            }
            values={{}}
            onFinishSuccessful={onFinished}
            title={<Trans>Nový API kľúč</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete vygenerovať nový API kľúč?</Trans>,
                createFetchUserGenerateApiKey(userInfo.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vygenerovať API kľúč.`)}
        />;
    }
}

export default GenerateApiKey;