import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import {UserInfo} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import { createUserSendPinChangeEmail } from '../../../backend/apiCalls';

/**
 * @dusan
 */

class ChangePin extends React.PureComponent {
    static propTypes = {
        userInfo: UserInfo.isRequired,
        onFinished: PropTypes.func.isRequired,
    };

    render() {
        const {userInfo = {}, onFinished} = this.props;
        const mail = userInfo.e_mail;

        return <FormModal
            openNode={
                <Button>
                    <Trans>Zmeniť PIN</Trans>
                </Button>
            }
            values={{mail}}
            onFinishSuccessful={onFinished}
            title={<Trans>Aktivácia účtu</Trans>}
            Form={generalNoParameterFormPC(
                <div>
                    <Trans>Naozaj chcete odoslať e-mail s pokynmi pre zmenu PIN na adresu</Trans>
                    {' '}
                    <span className="text-nowrap">{mail}</span>
                    {'?'}
                </div>,
                createUserSendPinChangeEmail()
            )}
            Response={SendPinChangeEmailResponse}
            Failed={generalFailedPC(t`Nepodarilo sa odoslať e-mail s pokynmi.`)}
        />;
    }
}

export default ChangePin;

class SendPinChangeEmailResponse extends React.PureComponent {
    static propTypes = {
        values: PropTypes.shape({
            mail: PropTypes.string.isRequired,
        }).isRequired,
    };

    render() {
        const {values} = this.props;
        return <div>
            <h4>{t`E-mail s pokynmi na zmenu PIN bol odoslaný na adresu`}</h4>
            {values.mail}
        </div>;
    }
}