import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import {UserInfo} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import ActivationForm from './form/ActivationForm';

/**
 * @fero
 */

class ActivateUser extends React.PureComponent {
    static propTypes = {
        userInfo: UserInfo.isRequired,
        onFinished: PropTypes.func.isRequired,
    };

    render() {
        const {userInfo, onFinished} = this.props;
        return <FormModal
            openNode={
                <Button type="primary">
                    <Trans>Aktivovať účet</Trans>
                </Button>
            }
            values={{userInfo}}
            onFinishSuccessful={onFinished}
            title={<Trans>Aktivácia účtu</Trans>}
            Form={ActivationForm}
            Response={ActivateUserResponse}
            Failed={generalFailedPC(t`Nepodarilo sa aktivovať účet.`)}
        />;
    }


}

export default ActivateUser;

class ActivateUserResponse extends React.PureComponent {
    render() {
        return <div>
            <h3>{t`Aktivácia účtu prebehla úspešne.`}</h3>
        </div>;
    }

}